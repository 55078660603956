export const environment = {
    firebase: {
        projectId: 'vizar-demo',
        apiKey: "AIzaSyC3IApjeZ3-2lL9j-FXPgerdNjfhlR72KY",
        authDomain: "vizar-demo.firebaseapp.com",
    },
    production: false,
    apiUrl: 'https://vizar-back-end-k33h63rirq-ew.a.run.app/',
    organizationIdTemp: '8f772d5a-953b-11ee-a74d-42010a400002',
    personalityModelIdTemp: 'dbd49618-953b-11ee-a74d-42010a400002'
};